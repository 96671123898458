<script>
import EntityForm from "@/mixins/EntityForm";
import WithVisitorLevelPermissions from "@/mixins/WithVisitorLevelPermissions";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    eventService: "eventService",
    contentService: "contentService",
    categoryService: "categoryService",
  },
})
export default class ContentForm extends mixins(
  EntityForm,
  WithVisitorLevelPermissions
) {
  model = {};
  languages = [];
  categories = [];

  get languagesOptions() {
    return this.languages.map((l) => {
      return {
        value: l.id,
        label: l.description,
      };
    });
  }

  get categoryList() {
    return this.categories.filter(l => l.isEnabled == true).map((l) => {
        return {
          value: l.id,
          label: l.code,
        };
    });
  }

  get elements() {
    return [
      {
        legend: this.translations.labels.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "type",
            type: "hidden",
          },
          {
            id: "idParent",
            type: "hidden",
          },
          {
            id: "idLanguage",
            label: this.translations.labels.content_form_language,
            type: "select",
            items: this.languagesOptions,
            required: true,
          },
          {
            id: "idCategory",
            label: this.translations.labels.content_form_category,
            type: "select",
            items: this.categoryList,
            required: true,
          },
          {
            id: "name",
            label: this.translations.labels.content_form_name,
            type: "text",
          },
          {
            id: "order",
            label: this.translations.labels.content_form_order,
            type: "number",
            rules: "gt:0|lte:255",
            required: true,
          },
          {
            id: "uri",
            label: this.translations.labels.content_form_uri,
            type: this.uriTypeField,
            hide: () => {
              return !this.showUri();
            },
          },
          {
            id: "infoLabel",
            label: "Info Label",
            type: "text",
            rules: "max:12",
          },
          {
            id: "alwaysVisibleName",
            label: "Name always visible",
            type: "switch",
          },
          {
            id: "infoUri",
            label: "Info Uri",
            type: this.uriTypeField,
            hide: () => {
              return !this.showUri();
            },
          },
          {
            id: "previewImageUri",
            label: this.translations.labels.content_form_previewImageUri,
            type: "media",
            mediaType: "image",
            hide: () => {
              return !this.showPreviewImageUri();
            },
          },
          {
            id: "description",
            label: this.translations.labels.content_form_description,
            type: this.descriptionTypeField,
            resetColumns: 1,
            hide: () => {
              return !this.showDescription();
            },
          },
        ],
      },
      {
        id: "visitorLevels",
        type: "visitor-levels-permissions",
        resetColumns: true,
        transcludeModel: true,
        legend: this.translations.labels.visitor_level,
        label: this.translations.labels.visitor_level,
        levels: this.currentVisitorLevelsOptions,
      },
    ];
  }

  get uriTypeField() {
    return this.model.type === "File" ? "media" : "text";
  }

  get descriptionTypeField() {
    return this.model.type === "HtmlContent" ? "wysiwyg" : "textarea";
  }

  get helpLink() {
    switch (this.model.type) {
      case "Video":
        return this.translations.pageHelp.content.video;
      case "VideoOnDemand":
        return this.translations.pageHelp.content.videoOnDemand;
      case "File":
        return this.translations.pageHelp.content.file;
      case "HtmlContent":
        return this.translations.pageHelp.content.htmlContent;
      default:
        return "";
    }
  }

  // description is needed for:
  //  * HtmlContent (as richTextEditor)
  //  * File (as textarea)
  showDescription() {
    return ["HtmlContent", "File"].includes(this.model.type);
  }

  // uri is needed for:
  //  * Video
  //  * VideoOnDemand
  //  * File
  showUri() {
    return ["Video", "File", "VideoOnDemand"].includes(this.model.type);
  }

  // previewImageUri is needed for:
  //  * Video
  //  * VideoOnDemand
  showPreviewImageUri() {
    return ["Video", "VideoOnDemand"].includes(this.model.type);
  }

  async created() {
    this.languages = await this.eventService.relatedLanguages(
      this.$route.params.idParent
    );
    this.types = await this.contentService.types();
    this.categories = await this.categoryService.list();
  }
}
</script>
